import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Error from "../images/404.png"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <div>
  <div className="back-to-home rounded d-none d-sm-block">
    <Link
      to="/"
      className="text-white rounded d-inline-block text-center"
    >
      <i className="mdi mdi-home" />
    </Link>
  </div>
  <section className="bg-home">
    <div className="home-center">
      <div className="home-desc-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 text-center">
              <img src={Error} className="img-fluid" alt />
              <div className="text-capitalize text-dark mb-4 error-page">
                Sayfa Bulunamadı
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <Link to="/" className="btn btn-outline-primary mt-4">
                Ana sayfaya dön
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

  </Layout>
)

export default NotFoundPage
